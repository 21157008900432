melvey = melvey || {};

melvey.log = function() {
  if (console && console.log) {
    console.log.apply(console, arguments);
  }
};

jQuery(function($) {

  var log = melvey.log;

  var toggleBodyWidth = function(setToDefault) {
    $body = $('body');

    if (setToDefault) {
      $body.width('auto');
    } else {
      $body.width($body.width());
    }
  };
  var toggleMenu = function(menu) {
    var $body = $('body'),
      menuClass = 'ui-' + menu + '-open';

    toggleBodyWidth($body.hasClass(menuClass));
    $body.toggleClass(menuClass);
  }

  $('.ui-menu').on('click', function(e) {
    e.preventDefault();
    toggleMenu($(this).data('menu'));
  });

  $('form textarea').autogrow();

  var SLIDESHOW_HTML = '<div class="slideshow"></div>';
  var buildSlideshowContent = function(images) {
    var $slideshow = $(SLIDESHOW_HTML),
      idx = $('#slideshow-container').data('slideshow.idx') + 1,
      $imageContainer = $slideshow.find('.images');


    for (var i = 0; i < images.length; i++) {
      $slideshow.append($('<img/>').attr('src', images[i]));
    }

    $slideshow.appendTo('#slideshow-container .slideshow-wrapper');

    $slideshow.attr('data-idx', idx);
    $('#slideshow-container').data('slideshow.idx', idx);

    return idx;
  };

  var setTitleBarContent = function(title, address, price, status, previewSheet) {
    $('#slideshow-container').find('.title').text(title);
    $('#slideshow-container').find('.address').text(address);
    $('#slideshow-container').find('.price').text(price);
    $('#slideshow-container').find('.status').text(status);
    if (previewSheet && previewSheet !== '') {
      $('#slideshow-container').find('.preview-sheet').show().attr('href', previewSheet);
    } else {
      $('#slideshow-container').find('.preview-sheet').hide()
    }
  }

  var SLIDESHOW_CONTAINER_HTML = '<div id="slideshow-container" class="slideshow-container"><div class="slideshow-overlay"></div><div class="slideshow-wrapper"><div class="titlebar"><div class="title-address"><div class="title"></div><div class="address"></div></div><div class="price-status"><div class="price"></div><div class="status"></div></div><a class="preview-sheet">View Preview Sheet</a><div class="right"><div class="caption"></div><a class="close" href="#">X</a></div></div><a href="#prev" class="prev">&lt;</a><a href="#next" class="next">&gt;</a><a href="#" class="back">Back to Properties</a></div></div>';
  var initSlideshows = function() {
    var $container = $('#slideshow-container');
    if (!$container.length) {

      $container = $(SLIDESHOW_CONTAINER_HTML);
      $container.appendTo('body');
      $container.data('slideshow.idx', 0);

    } else {
      log('slideshow container already created');
    }
    return $container;
  };

  initSlideshows();

  var openSlideShow = function($this, hideUI) {
    // if (idx !== undefined) {

      var title = $this.find('h3').text(),
          address = $this.find('h4').text(),
          price = $this.find('div span').eq(0).text(),
          status = $this.find('div span').eq(1).text(),
          previewSheet = $this.find('a').data('preview-sheet'),
          idx = $this.data('slideshow.idx');

      if (idx === undefined) {
        var images = []

        $this.find('.slideshow-images li').each(function() {
          images.push($(this).data('image'));
        });

        if (images.length) {
          idx = buildSlideshowContent(images);
          $this.data('slideshow.idx', idx);
        } else {
          log('no images found for element: ', $(this));
        }
      } else {
        log('already created for element: ', $(this));
      }

      setTitleBarContent(title, address, price, status, previewSheet);

      if (hideUI) {
        $('#slideshow-container').addClass('hidden-ui')
      } else {
        $('#slideshow-container').removeClass('hidden-ui')
      }

      var $slideshowToShow = $('.slideshow-container .slideshow[data-idx=' + idx + ']');

      if (!Modernizr.csstransforms) {
        $slideshowToShow.on('cycle-initialized cycle-slide-added cycle-slide-removed', function() {

            setTimeout(function() {
              var width = $('#slideshow-container .slideshow-wrapper').width(),
                height = $('#slideshow-container .slideshow-wrapper').height();

              $('#slideshow-container .slideshow-wrapper').css({
                marginLeft: height / 2 * -1,
                marginTop: width / 2 * -1
              });
            }, 50);
        });
      }

      $slideshowToShow.cycle({

        caption: '#slideshow-container .caption',
        next: '#slideshow-container .next',
        prev: '#slideshow-container .prev',
        slides: '> img',
        paused: true,
        log: false,
        swipe: true,
        fx: 'scrollHorz',
        swipeFx: 'scrollHorz',
        autoHeight: '100:42'
      });

      $slideshowToShow.cycle('jump', 0, 'none');
      $slideshowToShow.show();

      $('body').addClass('ui-slideshow-open');

      $('.slideshow-container').fadeIn();
  };

  var closeSlideShow = function() {
    $('#slideshow-container').fadeOut(function() {
      $('.slideshow-container .slideshow').hide();
      $('.slideshow-container .slideshow').cycle('destroy');
    });
    $('body').removeClass('ui-slideshow-open');

    if (history && history.pushState) {
      history.pushState("", document.title, location.pathname + location.search);
    } else {
      location.hash = '';
    }
  };

  $('.properties-list > li a').add('.vertical-property-list > li a').on('click', function(e) {
    e.preventDefault();

    var $ul = $(this).closest('ul');

    if (!$ul.hasClass('vertical-property-list') && $(this).attr('href') !== '#') {
      location.hash = '!/' + $(this).attr('href').slice(1)
    }

    openSlideShow($(this).parent('li'));
  });

  if (location.hash.length > 0 && location.hash.substr(0,2) == '#!') {
    var hash = '#' + location.hash.substr(3)
    $('.properties-list > li a').add('.vertical-property-list > li a').each(function() {
      if ($(this).attr('href') === hash) {
        openSlideShow($(this).parent('li'), true);
      }
    })
  }


  $('#slideshow-container').on('click', '.close, .slideshow-overlay, .back', function(e) {
    e.preventDefault();
    if (!$('#slideshow-container').hasClass('hidden-ui')) {
      closeSlideShow();
    }
  });
});
